import React, { useEffect, useState, useContext } from "react";
// import React, { useState } from "react";
import { TextField, Button, Typography, Box, Container } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useCookies } from "react-cookie";
import { NavLink, useNavigate } from "react-router-dom";
import BASE_URL from "../subcomponents/Config";
import useFormValidation from "../Hooks/useFormValidation";
import { toast, ToastContainer } from "react-toastify";
import { Context } from "../subcomponents/Context";

const AdminLoginForm = styled("form")({
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	marginTop: "50px",
	border: "1px solid #ccc",
	padding: "20px",
	borderRadius: "5px",
});

const AdminLogin = () => {
	const { message, setMessage } = useContext(Context);
	const [error, setError] = useState("");
	const nav = useNavigate();
	const initialFormState = {
		email: "",
		password: "",
	};
	const [cookies, setCookie] = useCookies([
		"adminToken",
		"adminRole",
		"adminId",
	]);
	const { formData, setErrors, errors, handleChange, validateForm } =
		useFormValidation(initialFormState);

	const handleLogin = async (e) => {
		e.preventDefault();

		const validationErrors = validateForm(formData);
		if (Object.keys(validationErrors).length === 0) {
			try {
				const response = await fetch(`${BASE_URL}/api/v1/auth/adminLogin`, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify(formData),
				});
				if (response.ok) {
					const data = await response.json();
					setCookie("adminToken", data.token, {
						path: "/admin",
						maxAge: data.tokenExpiryTime,
					});
					setCookie("adminRole", JSON.stringify(data.role), {
						path: "/admin",
						maxAge: data.tokenExpiryTime,
					});
					setCookie("adminId", data.id, {
						path: "/admin",
						maxAge: data.tokenExpiryTime,
					});
					nav("/admin");
				} else {
					const errorData = await response.json();
					setError(errorData.message);
				}
			} catch (error) {
				setError(error.message);
			}
		} else {
			setErrors(validationErrors);
		}
	};

	useEffect(() => {
		if (message) {
			toast.success(message);
			setMessage("");
		}
	}, [message]);

	return (
		<>
		<ToastContainer position="top-center" autoClose="2000" />
		<Container maxWidth="xs">
			<AdminLoginForm onSubmit={handleLogin}>
				<Typography variant="h2" gutterBottom>
					LOGIN
				</Typography>
				{error && (
					<Typography variant="subtitle2" color="error" gutterBottom>
						{error}!!
					</Typography>
				)}
				<Box width="100%" marginBottom="20px">
					<TextField
						label="Email"
						variant="outlined"
						name="email"
						fullWidth
						value={formData.email}
						onChange={handleChange}
					/>
					{errors.email && <span style={{ color: "red" }}>{errors.email}</span>}
				</Box>
				<Box width="100%" marginBottom="20px">
					<TextField
						label="Password"
						type="password"
						name="password"
						variant="outlined"
						fullWidth
						value={formData.password}
						onChange={handleChange}
					/>
					{errors.password && (
						<span style={{ color: "red" }}>{errors.password}</span>
					)}
				</Box>
				<Button type="submit" variant="contained" color="secondary">
					Login
				</Button>
			</AdminLoginForm>
		</Container>
		</>
	);
};

export default AdminLogin;
