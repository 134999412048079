import React, { useEffect, useState } from "react";
import BASE_URL from "../../subcomponents/Config";
import { useCookies } from "react-cookie";
import { NavLink, useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { FaRegCalendar } from "react-icons/fa6";
import { Box, CircularProgress } from "@mui/material";
import "../../Styles/Vacancy/vacancy.scss";
import { VscFilePdf } from "react-icons/vsc";
import TextTruncate from "../components/TextTruncate";
import { CiLink } from "react-icons/ci";
import useLoading from "../../Hooks/useLoading";
import Loading from "../components/Loading";

//This is Vacancy Components in Navbar
const Notices = () => {
	const nav = useNavigate();
	const { loading, showLoading, hideLoading } = useLoading();
	const [vacancies, setVacancies] = useState([]);
	const [error, setError] = useState("");
	const [cookies, setCookie] = useCookies(["userToken"]);
	const [token, setToken] = useState(cookies.userToken || "");
	const [maxLength, setMaxLength] = useState(23);

	useEffect(() => {
		const updateMaxLength = () => {
			if (window.innerWidth <= 768) {
				setMaxLength(25);
			} else {
				setMaxLength(58);
			}
		};
		updateMaxLength();
		// Update maxLength on window resize
		window.addEventListener("resize", updateMaxLength);
		// Cleanup event listener on component unmount
		return () => {
			window.removeEventListener("resize", updateMaxLength);
		};
	}, []);

	useEffect(() => {
		setToken(cookies.userToken || "");
	}, [cookies]);

	useEffect(() => {
		Articles();
	}, []);

	const Articles = async () => {
		showLoading();
		try {
			const response = await fetch(`${BASE_URL}/api/v1/vacancy/vacancyList`, {
				method: "GET",
				headers: {
					Authorization: `Bearer ${token}`,
					mode: "cors",
				},
			});
			if (response.ok) {
				const getApi = await response.json();
				setVacancies(getApi.vacancies);
				hideLoading();
			} else {
				const error = await response.json();
				setError(error.message);
				hideLoading();
			}
		} catch (error) {
			showLoading();
		}
	};

	const handleClick = (data) => {
		if (data && data.vacancyUrl) {
			window.open(data.vacancyUrl, "_blank");
		}
	};

	return (
		<>
			<Navbar />
			{loading ? <Loading /> : null}
			{error && <div style={{ color: "red" }}>{error}</div>}
			{!loading && (
				<main>
					<div className="vacancies">
						<h3>Vacancies</h3>
						<hr />
						<div className="vacancy_grid">
							{vacancies &&
								vacancies.map((data, index) => {
									return (
										<div className="inner_vacancy" key={index}>
											<div className="title">{data.title}</div>
											<NavLink
												to={data.vacancyPictureUrl}
												target="_blank"
												key={index}
											>
												<div className="pdf_wrapper">
													<div className="pdf_icon">
														<VscFilePdf />
													</div>
													<div className="icon">
														<TextTruncate
															text={data.picture || ""}
															maxLength={maxLength}
														/>
													</div>
												</div>
											</NavLink>

											<div
												className="vacancy_wrapper"
												onClick={() => handleClick(data)}
												style={{ cursor: "pointer" }}
											>
												<div className="vacancy_icon">
													<CiLink />
												</div>
												<div className="link">
													<TextTruncate
														text={data.vacancyUrl || ""}
														maxLength={maxLength}
													/>
												</div>
											</div>

											<div className="date">
												<FaRegCalendar /> {data.addedDate}
											</div>
										</div>
									);
								})}
						</div>
						{loading ? (
							<Box
								sx={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									height: "200px",
								}}
							>
								<CircularProgress />
							</Box>
						) : null}
					</div>
				</main>
			)}
			<Footer />
		</>
	);
};

export default Notices;
