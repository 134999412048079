import React, { useState, useEffect, useRef } from "react";
import { styled } from "@mui/material/styles";
import { useNavigate, useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import "../adminComponents/admin.scss";
import useFormValidation from "../../Hooks/useFormValidation";
import HelpIcon from "@mui/icons-material/Help";
import Tooltip from "@mui/material/Tooltip";
import {
	Card,
	CardContent,
	Typography,
	Box,
	Table,
	TableBody,
	TableCell,
	TableRow,
	CircularProgress,
	Button,
	Select,
	MenuItem,
	Chip,
	TextField,
} from "@mui/material";
import BASE_URL from "../../subcomponents/Config.jsx";
import { toast, ToastContainer } from "react-toastify";

const initialFormState = {
	duration: "",
};

const tablevalue = [
	{ title: "First Name", value: "firstName" },
	{ title: "Last Name", value: "lastName" },
	{ title: "Email", value: "email" },
	{ title: "Mobile Number", value: "mobileNumber" },
	{ title: "Role", value: "role" },
];

const PaymentForm = styled("form")({
	width: "100%",
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	// border: "1px solid #ccc",
	padding: "5px",
	borderRadius: "5px",
});

export default function UserDetail() {
	const itemsPerPage = 8;
	const { id, role } = useParams();
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [listLoading, setListLoading] = useState(false);
	const [error, setError] = useState(null);
	const [cookies] = useCookies(["adminToken"]);
	const [courseId, setCourseId] = useState("");
	const [courses, setCourses] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const nav = useNavigate();
	const [adminToken, setAdminToken] = useState(cookies.adminToken || "");
	const selectRef = useRef(null);

	const {
		formData,
		setFormData,
		setErrors,
		errors,
		handleChange,
		validateForm,
	} = useFormValidation(initialFormState);

	useEffect(() => {
		setAdminToken(cookies.adminToken || "");
	}, [cookies]);

	function Back() {
		nav(-1);
	}

	useEffect(() => {
		getData();
	}, []);

	const getData = async () => {
		setLoading(true);
		try {
			const response = await fetch(`${BASE_URL}/api/v1/user/${id}`, {
				method: "GET",
				headers: {
					Authorization: `Bearer ${adminToken}`,
				},
			});
			if (response.ok) {
				const apiData = await response.json();
				setData(apiData.user);
				setLoading(false);
			} else {
				const error = await response.json();
				setError(error.message);
				setLoading(false);
			}
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};

	const fetchData = async () => {
		setListLoading(true);
		try {
			const courseList = await fetch(
				`${BASE_URL}/api/v1/course/courseList?page=${currentPage}&size=${itemsPerPage}`,
				{
					method: "GET",
				}
			);
			if (courseList.ok) {
				const apiData = await courseList.json();
				setCourses([...courses, ...apiData.courses]);
				setTotalPages(apiData.totalPages);
				setListLoading(false);
			} else {
				const error = await courseList.json();
				setError(error.message);
				setListLoading(false);
			}
		} catch (error) {
			console.error("Error fetching data:", error);
			setListLoading(false);
		}
	};

	useEffect(() => {
		if (currentPage <= totalPages) {
			fetchData();
		}
	}, [currentPage]);

	useEffect(() => {
		const handleScroll = (event) => {
			const bottom =
				event.target.scrollHeight - event.target.scrollTop ===
				event.target.clientHeight;
			if (bottom && !listLoading && currentPage < totalPages) {
				setCurrentPage((prevPage) => prevPage + 1);
			}
		};

		const node = selectRef.current?.children[2];
		if (node) {
			node.addEventListener("scroll", handleScroll);
		}

		return () => {
			if (node) {
				node.removeEventListener("scroll", handleScroll);
			}
		};
	}, [listLoading, currentPage, totalPages]);

	const handleSubmit = async (e) => {
		e.preventDefault();
		const validationErrors = validateForm(formData);
		console.log(errors);
		if (Object.keys(validationErrors).length === 0) {
			const data = new FormData();
			data.append("duration", formData.duration);

			// data.append("depositedAmount", formData.depositedAmount);
			// data.append("remarks", formData.remarks);

			setLoading(true);
			try {
				const response = await fetch(
					`${BASE_URL}/api/v1/course/${courseId}/enroll/user/${id}`,
					{
						method: "POST",
						headers: {
							Authorization: `Bearer ${adminToken}`,
							"Content-Type": "application/json",
						},
						body: JSON.stringify(formData),
					}
				);

				if (response.ok) {
					const { message } = await response.json();
					toast.success(message);
					setCourseId("");
					setFormData(initialFormState);
				} else {
					const error = await response.json();
					toast.error(error.message);
				}
			} catch (error) {
				console.log("An error occurred:", error.message);
				setError("An unexpected error occurred. Please try again.");
			} finally {
				setLoading(false);
			}
		} else {
			setErrors(validationErrors);
		}
	};

	const handleEnrollList = () => {
		nav(`/admin/ADMISSION_DEPARTMENT/user/courseEnrolled/${data.userId}`);
	};

	// chages made for the trending

	const handleMediaGrant = async () => {
		try {
			const grantaccess = data.canDownloadMediaPlayer;
			const response = await fetch(
				`${BASE_URL}/api/v1/downloadPermission/${id}`,
				{
					method: "POST",
					headers: {
						Authorization: `Bearer ${adminToken}`,
					},
					body: JSON.stringify(!grantaccess),
				}
			);
			if (response.ok) {
				const { message } = await response.json();
				toast.success(message);
				getData();
			} else {
				const error = await response.json();
				setError(error.message);
			}
		} catch (error) {
			console.log("An error occurred:", error.message);
		}
	};
	// end of changes

	const handleMediaPlayerStatus = async () => {
		setLoading(true);
		try {
			const response = await fetch(
				`${BASE_URL}/api/v1/resetDownloadedStatus/${id}`,
				{
					method: "POST",
					headers: {
						Authorization: `Bearer ${adminToken}`,
					},
				}
			);
			if (response.ok) {
				const { message } = await response.json();
				setLoading(false);
				toast.success(message);
				getData();
			} else {
				const error = await response.json();
				setError(error.message);
				setLoading(false);
			}
		} catch (error) {
			console.log("An error occured:", error.message);
		}
	};

	return (
		<Box>
			<ToastContainer position="top-center" autoClose="2000" />
			<Button
				onClick={() => Back()}
				variant="outlined"
				color="secondary"
				style={{ marginBottom: "0.2rem" }}
			>
				Back
			</Button>
			<Card variant="outlined">
				<CardContent>
					<div style={{ display: "flex", justifyContent: "space-between" }}>
						<Typography variant="h3">User Details</Typography>
						<Chip
							sx={{
								backgroundColor: "primary.main",
								color: "#fff",
							}}
							size="medium"
							label="View Enrolled Courses"
							onClick={() => handleEnrollList()}
						/>
					</div>
					<Box
						sx={{
							overflow: {
								xs: "auto",
								sm: "unset",
							},
						}}
					>
						<Table
							aria-label="simple table"
							sx={{
								mt: 3,
							}}
						>
							{!loading && data ? (
								<>
									<TableBody>
										{tablevalue &&
											tablevalue.map((table, index) => (
												<TableRow key={index}>
													<TableCell>
														<Typography
															sx={{
																fontSize: "15px",
																fontWeight: "500",
															}}
														>
															{table.title}
														</Typography>
													</TableCell>
													<TableCell>
														<Typography
															sx={{
																fontSize: "15px",
																fontWeight: "500",
															}}
														>
															{data[table.value]}
														</Typography>
													</TableCell>
												</TableRow>
											))}
										{/* changes made for trending */}
										{role === "ADMISSION_DEPARTMENT" ? (
											<TableRow>
												<TableCell>
													<Typography
														sx={{
															fontSize: "15px",
															fontWeight: "500",
														}}
													>
														Media Player Access
													</Typography>
												</TableCell>
												<TableCell>
													<Chip
														sx={{
															fontSize: "15px",
															fontWeight: "500",
															ml: "45px",
															backgroundColor: data.canDownloadMediaPlayer
																? "error.main"
																: "primary.main",
															color: "#fff",
														}}
														size="small"
														label={
															data.canDownloadMediaPlayer
																? "Remove access"
																: "Set Granted"
														}
														onClick={handleMediaGrant}
													/>
												</TableCell>
											</TableRow>
										) : null}

										{/* end of trending button chagnes */}

										<TableRow>
											<TableCell>
												<Typography
													sx={{
														fontSize: "15px",
														fontWeight: "500",
													}}
												>
													Enroll to a course
												</Typography>
											</TableCell>
											<TableCell
												style={{ display: "flex", flexDirection: "column" }}
											>
												<Select
													value={courseId}
													displayEmpty
													renderValue={(selected) => {
														if (selected.length === 0) {
															return <em>--Select Course--</em>;
														}
														return selected;
													}}
													onChange={(e) => setCourseId(e.target.value)}
													MenuProps={{
														PaperProps: {
															onScroll: (event) => {
																const bottom =
																	event.target.scrollHeight -
																		event.target.scrollTop ===
																	event.target.clientHeight;
																if (
																	bottom &&
																	!listLoading &&
																	currentPage < totalPages
																) {
																	setCurrentPage((prevPage) => prevPage + 1);
																}
															},
															style: {
																maxHeight: 200,
																minWidth: 300, // adjust as needed
															},
														},
													}}
													ref={selectRef}
													style={{ margin: "5px" }}
												>
													{courses.map((course) => (
														<MenuItem key={course.id} value={course.id}>
															{course.title}
														</MenuItem>
													))}
													{listLoading && (
														<MenuItem disabled>
															<CircularProgress size={24} />
														</MenuItem>
													)}
												</Select>

												<PaymentForm onSubmit={handleSubmit}>
													<TextField
														fullWidth
														label="Duration (in days)"
														name="duration"
														value={formData.duration}
														onChange={handleChange}
														error={!!errors.duration}
														helperText={errors.duration}
														type="Number"
														variant="outlined"
														placeholder="Enter course duration"
														sx={{ mb: 3 }}
													/>

													<Button
														disabled={courseId === ""}
														type="submit"
														style={{
															width: "100%",
															border: "1px solid #ccc",
															padding: "1rem",
														}}
													>
														Enroll
													</Button>
												</PaymentForm>
											</TableCell>
										</TableRow>
										{/* changes made for media download status */}
										{role === "ADMISSION_DEPARTMENT" ? (
											<TableRow>
												<TableCell>
													<Typography
														sx={{
															fontSize: "15px",
															fontWeight: "500",
														}}
													>
														Media Player Download Status
														<Tooltip
															arrow
															title="This is used to allow the user to re-download the mediaplayer."
															placement="top"
														>
															<HelpIcon sx={{ paddingTop: "11px" }} />
														</Tooltip>
													</Typography>
												</TableCell>
												<TableCell>
													<Chip
														sx={{
															fontSize: "15px",
															fontWeight: "500",
															ml: "45px",
															backgroundColor: data.mediaPlayerDownloaded
																? "primary.main"
																: "error.main",
															color: "#fff",
														}}
														size="small"
														label={
															data.mediaPlayerDownloaded
																? "Downloaded"
																: "UnDownloaded"
														}
														onClick={handleMediaPlayerStatus}
													/>
												</TableCell>
											</TableRow>
										) : null}

										{/* end of media download status button chagnes */}
									</TableBody>
								</>
							) : (
								<Box
									sx={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										height: "200px",
									}}
								>
									<CircularProgress />
								</Box>
							)}
							{error && (
								<Box
									sx={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										height: "200px",
									}}
								>
									{error}
								</Box>
							)}
						</Table>
					</Box>
				</CardContent>
			</Card>
		</Box>
	);
}
