// components/CourseSelect.js

import React, { useState, useEffect, useRef } from "react";
import { Select, MenuItem, CircularProgress } from "@mui/material";
import BASE_URL from "../../../subcomponents/Config";

const CourseSelect = ({ selectedCourseId, onSelectCourse }) => {
	const [courses, setCourses] = useState([]);
	const [listLoading, setListLoading] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const itemsPerPage = 8;
	const selectRef = useRef(null);

	// Fetch courses function
	const fetchCourses = async () => {
		setListLoading(true);
		try {
			const response = await fetch(
				`${BASE_URL}/api/v1/course/courseList?page=${currentPage}&size=${itemsPerPage}`,
				{
					method: "GET",
				}
			);

			if (response.ok) {
				const apiData = await response.json();
				setCourses([...courses, ...apiData.courses]);
				setTotalPages(apiData.totalPages);
			} else {
				const error = await response.json();
				console.error("Error fetching courses:", error.message);
			}
		} catch (error) {
			console.error("Error fetching data:", error);
		}
		setListLoading(false);
	};

	useEffect(() => {
		if (currentPage <= totalPages) {
			fetchCourses();
		}
	}, [currentPage]);

	useEffect(() => {
		const handleScroll = (event) => {
			const bottom =
				event.target.scrollHeight - event.target.scrollTop ===
				event.target.clientHeight;
			if (bottom && !listLoading && currentPage < totalPages) {
				setCurrentPage((prevPage) => prevPage + 1);
			}
		};

		const node = selectRef.current?.children[2];
		if (node) {
			node.addEventListener("scroll", handleScroll);
		}

		return () => {
			if (node) {
				node.removeEventListener("scroll", handleScroll);
			}
		};
	}, [listLoading, currentPage, totalPages]);

	return (
		<Select
			value={selectedCourseId}
			displayEmpty
			renderValue={(selected) => {
				if (selected.length === 0) {
					return <em>--Select Course--</em>;
				}
				return selected;
			}}
			onChange={(e) => onSelectCourse(e.target.value)}
			MenuProps={{
				PaperProps: {
					onScroll: (event) => {
						const bottom =
							event.target.scrollHeight - event.target.scrollTop ===
							event.target.clientHeight;
						if (bottom && !listLoading && currentPage < totalPages) {
							setCurrentPage((prevPage) => prevPage + 1);
						}
					},
					style: {
						maxHeight: 200,
						minWidth: 300, // adjust as needed
					},
				},
			}}
			ref={selectRef}
		>
			{courses.map((course) => (
				<MenuItem key={course.id} value={course.id}>
					{course.title}
				</MenuItem>
			))}
			{listLoading && (
				<MenuItem disabled>
					<CircularProgress size={24} />
				</MenuItem>
			)}
		</Select>
	);
};

export default CourseSelect;
